
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 2px 0;
}

.avatar {
  @include size(24px);
  min-width: 24px;

  margin-right: 10px;
}

.label {
  white-space: nowrap;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user_tag_close_col {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.user_tag_close {
  margin-left: $gl-spacer-3xs;
  cursor: pointer $important;
  flex: 1;
  color: $theme-dark-lt;
}
