// ==========================================================================
//  #Size
// ==========================================================================

// Sizing shortcuts

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin size-text-center($width, $height: $width) {
  @include size($width, $height);

  text-align: center;
  line-height: $height;
}


@function linesHeight($linesNumber, $lineHeight: $gl-line-height-base) {
  @return $lineHeight * $linesNumber * 1em;
}
