
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
//
// Radio
// --------------------------------------------------
//[TODO]: add docs

// The implementation is based on https://github.com/flatlogic/awesome-bootstrap-checkbox

// Default vars
$radio-offset              : $gl-spacer-s;
$radio-spacing             : 25px !default; //spacing between checkbox and label
$radio-disabled-opacity    : $gl-input-disabled-opacity !default;
$radio-bg-color            : $theme-light-lt !default;
$radio-outline-color       : #f8f8f8;
$radio-outline-color-mobile: rgba(0, 0, 0, 0.03);
$radio-checked-color       : $theme-dark-dk !default;
$radio-size                : 24px !default;
$radio-padding             : 5px !default;
$radio-transition          : all 150ms $gl-timing-func !default;
$radio-label-color         : $theme-dark-dkr !default;
$radio-checked-label-color : $theme-dark-dk !default;
$radio-label-font-size     : 20px !default;
$radio-label-disabled-color: $theme-dark-lt !default;

%pseudo-checkbox {
  cursor: pointer;
  content: "";
  display: block;
  border-radius: 50%;
}

.test_answer:hover {
  background-color: $clr-white-dk;
}

.container {
  cursor: default;
  display: flex;
  align-items: center;
  // padding: $gl-spacer-s $gl-spacer-l;
  border-radius: 28px;
  height: 48px;
  padding: 0 $gl-spacer-s;


  & + & {
    margin-top: $gl-spacer-3xs;
  }
}

.checked {
  background-color: $radio-outline-color-mobile;

  @include desktop {
    background-color: $radio-outline-color;
  }
}

.correct {
  background-color: $theme-light-lt;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
}

.disabled:not(.correct) {
  opacity: 0.5;
  background-color: transparent;
}

.label {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: $radio-spacing;
  color: $radio-label-color;
  font-size: rem($radio-label-font-size);
  line-height: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;

  // redefine default styling
  p {
    margin: 0;
  }
}

.radio {
  display: block;
  position: relative;
  // Checkbox background
  &::before {
    @extend %pseudo-checkbox;

    width: rem($radio-size);
    height: rem($radio-size);
    background-color: $radio-bg-color;
    border: 2px solid rgba(0, 0, 0, 0.12);
  }

  // Checkbox State
  &::after {
    @extend %pseudo-checkbox;
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem($radio-size - $radio-padding * 2);
    height: rem($radio-size - $radio-padding * 2);
    background: $radio-checked-color;
    transform: translate(-50%, -50%) scale(0, 0);
    transition: $radio-transition;
    z-index: 1;
  }
}

// Checked State
.checked .radio::after {
  transform: translate(-50%, -50%) scale(1);
}

.checked .radio::before {
  border-color: $theme-dark-dkr;
}

.checked .label {
  color: $radio-checked-label-color;
}

.disabled .radio {

  &::before {
    cursor: not-allowed;
  }

  &::after {
    cursor: not-allowed;
  }
}


.input {
  margin: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 2;

  @include center-absolute();

  @include size($radio-size);

  // Emulate input focus outline for the pseudo element
  // &:focus + label::before {
  //   outline: thin dotted;
  //   outline: 5px auto -webkit-focus-ring-color;
  //   outline-offset: -2px;
  // }
}

.errorMessage {
  font-size: $gl-font-size-xxs;
  color: $gl-validation-error-color;
  margin: $gl-spacer-xs 0 0 0;
}

.check {
  padding-right: $gl-spacer-m;
}
