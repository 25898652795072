@import '../tools/tools.colors';
///* ========================================================================
//   #COLORS
//   ======================================================================== */

// There are two prefixes that are used for color variables:
// 1. $clr indicates simple colors
// 2. $theme defines a complete set of colors that are used for website styling
// !!! Developers should use theme colors only for their css rules

// Read more:
// https://codepen.io/nicetransition/pen/QjwbRg
// http://fixate.it/blog/make-your-css-variable-names-suck-less/

// All colors that are used in the project must be defined here
$clr-french-blue    : #4267b2 !default;
$clr-brick          : #F43C13 !default;
//$clr-coral-pink     : #ff6976 !default;
$clr-dark-pink      : #ad245c !default;
//$clr-light-teal     : #72e1bf !default;
$clr-greenish-teal  : #43d6a9 !default;
$clr-blue-green     : #018383 !default;
$clr-blue-green-lt	: #f6f8f8 !default;
$clr-clay           : #2E3541 !default;
$clr-pale-grey      : #F2F5F9 !default;
//$clr-pale-grey-two  : #f9fafa !default;
// $clr-warm-grey-lt   : #9b9b9b !default;
//$clr-pale-grey-three: #f9f9fc !default; // [TODO] Add to the Style Guide
$clr-pale-grey-three: #f8f8f9 !default;
// $clr-greyish        : #b5b5b5 !default;
$clr-greyish-lt     : #ddd !default;
//$clr-white-dkst     : #eee !default;
$clr-white-dk       : #f5f5f5 !default;
$clr-white          : #fff !default;
$clr-warm-grey      : #767676 !default;
// $clr-greyish-brown  : #333 !default;
$clr-black          : #000 !default;

// TODO: add theme color
$clr-charcoal-grey  : #2e3541 !default;
$clr-light-teal-alt : #79dfdf !default;
$clr-rosy-pink      : #f76398 !default;
$clr-lavender       : #c0bbf3 !default;
$clr-sky-blue       : #69a7f6 !default;
$clr-wheat          : #fbc97e !default;
$clr-ice-blue       : #eaf7ff !default;
$clr-ice            : #defffa !default;
$clr-light-pink     : #ffeaea !default;
$clr-sepia          : #905D26 !default;
$clr-light-navy-blue: #32599d;
$cl-dark-sky-blue   : #4a90e2;

$clr-paypal-pay     : #003087;
$clr-paypal-pal     : #009cde;

$clr-white-ice      : #F1FAF8;
$clr-cascade        : #F7F8FD !default;
$clr-stone          : #a79f86 !default;
$clr-azure          : #f1faf8 !default;
$clr-sand           : #f5f4f1 !default;

// V2
$clr-light-pink-two:        #FAF2EA !default;
$clr-light-pink-three:      #fdf7f0 !default;
$clr-light-blue:            #EAEEF2 !default;
$clr-melon:                 #ff7c5f !default;
$clr-melon-2:               #ff795c !default;
$clr-aquamarine:            #00d69e !default;
$clr-ice-2:                 #EDF6F6 !default;
$clr-brownish-grey:         rgba($clr-black, 0.69) !default;
$clr-light-teal:            #85c2e2 !default;
$clr-light-teal-2:          #9DADFF !default;
$clr-coral:                 #f36c65 !default;
$clr-light-blue-grey:       #def0ec !default;
$clr-purple:                #b35eba !default;
$clr-dark-font:             #121212 !default; // TODO: replace with  Dark-dkr
$clr-acid-green:            #5ADF4E !default; // TODO: replace with  Dark-dkr


// New Colors TODO: review
$clr-bright-green:          #00BE9F;
$clr-gray:					        rgba($clr-black, 0.61) !default;
$clr-gold-1:                #D1BB78 !default;
$clr-gold-2:                #C2AD6C !default;
$clr-gold-3:                #f8d6b7 !default;
$clr-light-sage:            #abc5ab !default;
$clr-light-sage-2:          #E7FBF4 !default;
$clr-light-sage-3:          #F6FBF9 !default;
$clr-white-2:               #FAFEFC !default;
$clr-white-3:               rgba(240, 225, 202, 0);
$clr-brown-1:               #413E3A !default;
$clr-brown-2:               #34312C !default;
$clr-brown-3:               #3E3B37 !default;
//TODO: F7F9F9 Can be replaced by $clr-blue-green-lt
$clr-white-4:               #F7F9F9 !default;
$clr-white-dkr:             #F2F2F2 !default;
$clr-navy-ltr:              #4B74FF !default;

$clr-gold-1:                  #F2CCA9 !default;
$clr-gold-2:                  #FFE2C7 !default;


// Study guide cover colors
$clr-sg-1a:                   #98D091 !default;
$clr-sg-1b:                   #56A54E !default;
$clr-sg-2a:                   #FF8F70 !default;
$clr-sg-2b:                   #F86B43 !default;
$clr-sg-3a:                   #D89AD7 !default;
$clr-sg-3b:                   #CA7AC8 !default;
$clr-sg-4a:                   #FEA260 !default;
$clr-sg-4b:                   #F07300 !default;
$clr-sg-5a:                   #81DABF !default;
$clr-sg-5b:                   #00A97D !default;
$clr-sg-6a:                   #A4BEFF !default;
$clr-sg-6b:                   #6D92F2 !default;

// THEME COLORS

// Theme color variables should follow the flollowing formula for consistent naming.
//
// $theme-{category}-{alternative}-{hue/tint}
//
//
// 1. Categories:
//   Primary - Main color in the palette
//   Secondary - Contrast of primary color
//   Dark - Darkest color in the palette
//   Light - Lightest color in the palette
//   Accent - Highlight/Accent color to emphasize a part of the interface
//
// 2. Alternatives
//   alt - keyword for alternative color variant in the same category
//
// 3. Hues/Tints
// Tints and hues are named with respect to the main colour
// they are associated with, plus a suffix to describe their
// lightness or darkness with respect to that main colour:
//
//  lt => light
//  ltr => lighter
//  ltst => lightest
//
//  dk => dark
//  dkr => darker
//  dkst => darkest


$theme-primary        : $clr-blue-green !default;
$theme-primary-lt     : tint($theme-primary, 16%) !default;
$theme-primary-alt    : $clr-dark-pink !default;
$theme-primary-alt-lt : tint($theme-primary-alt, 16%) !default;
$theme-primary-dk     : $clr-clay !default;
$theme-primary-accent : tint($theme-primary, 96%) !default;
$theme-primary-ltr    : rgba($theme-primary, 0.03) !default;
$theme-primary-ltst   : $clr-pale-grey !default;

$theme-dark-dkr       : $clr-black !default;
// TODO: remove
$theme-dark-dk        : $clr-dark-font !default;
// $theme-dark-dk        : tint($theme-dark-dkr, 16%) !default;

$theme-dark           : $clr-gray !default;
$theme-dark-lt        : rgba($theme-dark-dkr, 0.54) !default;
// $theme-dark           : $clr-warm-grey !default;
// $theme-dark-ltr       : $clr-greyish !default;
$theme-dark-ltr       : rgba($theme-dark-dkr, 0.25) !default;

$theme-light-dk       : rgba($theme-dark-dkr, 0.065) !default;
$theme-light-dkr      : $clr-white-dkr !default;
$theme-light          : $clr-white-dk !default;
$theme-light-alt      : rgba($clr-white, 0.93) !default;
$theme-light-lt       : $clr-white !default;

$theme-alert          : $clr-brick !default;
$theme-alert-lt       : tint($theme-alert, 96%) !default;
$theme-success        : $theme-primary !default;
$theme-social-fb      : $clr-french-blue !default;
$theme-accent         : $cl-dark-sky-blue !default;
$theme-accent-alt     : $clr-sepia !default;
$theme-accent-alt-lt  : lighten($theme-accent-alt, 54%);

// V2
$theme-secondary        : $clr-light-pink-two !default;
$theme-secondary-lt     : $clr-light-pink-three !default;
$theme-secondary-dk     : $clr-melon !default;
$theme-secondary-alt    : $clr-aquamarine !default;
$theme-secondary-alt-lt : $clr-ice-2 !default;
$theme-dark-alt         : $clr-brownish-grey !default;

// TODO: review naming
$theme-gold             : $clr-gold-3 !default;
$theme-coral            : $clr-coral !default;
$theme-blue-lt          : $clr-light-teal-2 !default;
$theme-blue             : $clr-light-teal !default;
$theme-blue-dk          : $clr-navy-ltr !default;
$theme-melon-2          : $clr-melon-2 !default;
$theme-cascade          : $clr-cascade !default;
$theme-stone            : $clr-stone !default;
$theme-purple           : $clr-purple !default;
$theme-acid-green       : $clr-acid-green !default;
$theme-bright-green     : $clr-bright-green !default;


// Each key/value pair can be overrided in a particular context
// Example:
// p {
//   // use default color: $theme-primary
//   background: map-get($theme-clrs, primary);
// }
//
// p.green {
//   $theme-clrs: ('primary' : #008000);
//   // use #008000 in the p.green selector context
//   background: map-get($theme-clrs, primary);
// }
//
// p.default {
//   // use default color again: $theme-primary
//   background: map-get($theme-clrs, primary);
// }

$theme-clrs: () !default;
$theme-clrs: map-merge(
  (
    'primary'        : $theme-primary,
    'primary-lt'     : $theme-primary-lt,
    'primary-alt'    : $theme-primary-alt,
    'primary-alt-lt' : $theme-primary-alt-lt,
    'primary-ltr'    : $theme-primary-ltr,
    'primary-ltst'   : $theme-primary-ltst,
    'primary-accent' : $theme-primary-accent,
    'dark-dkr'       : $theme-dark-dkr,
    'dark-dk'        : $theme-dark-dk,
    'dark'           : $theme-dark,
    'dark-lt'        : $theme-dark-lt,
    'dark-ltr'       : $theme-dark-ltr,
    'light-dk'       : $theme-light-dk,
    'light-dkr'      : $theme-light-dkr,
    'light'          : $theme-light,
    'light-alt'      : $theme-light-alt,
    'light-lt'       : $theme-light-lt,
    'alert'          : $theme-alert,
    'accent'         : $theme-accent,
    'accent-alt'     : $theme-accent-alt,
    'accent-alt-lt'  : $theme-accent-alt-lt,

    // v2
    'secondary'        : $theme-secondary,
    'secondary-lt'     : $theme-secondary-lt,
    'secondary-dk'     : $theme-secondary-dk,
    'secondary-alt'    : $theme-secondary-alt,
    'secondary-alt-lt' : $theme-secondary-alt-lt,
    'dark-alt'         : $theme-dark-alt,
    'coral'            : $theme-coral,
    'blue-lt'          : $theme-blue-lt,
    'blue'             : $theme-blue,
    'blue-dk'          : $theme-blue-dk,
    'melon-2'          : $theme-melon-2,
    'cascade'          : $theme-cascade,
    'stone'            : $theme-stone,
    'gold'             : $theme-gold,
    'purple'           : $theme-purple,
    'acid-green'       : $theme-acid-green,
    'bright-green'     : $theme-bright-green
  ),
  $theme-clrs
);

// GRADIENTS

$theme-gradient-colors-primary  : ($clr-greenish-teal, $clr-light-teal-alt);
$theme-gradient-colors-warm     : ($clr-wheat, $clr-rosy-pink);
$theme-gradient-colors-cold     : ($clr-lavender, $clr-sky-blue);
$theme-gradient-colors-grey     : ($clr-white, $clr-pale-grey-three);
$theme-gradient-colors-ice      : ($clr-white, $clr-ice);
$theme-gradient-colors-ice-blue : ($clr-white, $clr-ice-blue);
$theme-gradient-colors-pink     : ($clr-white, $clr-light-pink);
$theme-gradient-colors-sage     : ($clr-light-sage-3, $clr-light-sage-2);
$theme-gradient-colors-white    : ($clr-white-2, $clr-white-3);
$theme-gradient-colors-brown    : ($clr-brown-1, $clr-brown-2);
$theme-gradient-colors-sg-1     : ($clr-sg-1a, $clr-sg-1b);
$theme-gradient-colors-sg-2     : ($clr-sg-2a, $clr-sg-2b);
$theme-gradient-colors-sg-3     : ($clr-sg-3a, $clr-sg-3b);
$theme-gradient-colors-sg-4     : ($clr-sg-4a, $clr-sg-4b);
$theme-gradient-colors-sg-5     : ($clr-sg-5a, $clr-sg-5b);
$theme-gradient-colors-sg-6     : ($clr-sg-6a, $clr-sg-6b);

// [TODO] Need better naming for gradients
$theme-gradient-primary   : linear-gradient(315deg, nth($theme-gradient-colors-primary, 1), nth($theme-gradient-colors-primary, 2));
$theme-gradient-warm      : linear-gradient(135deg, nth($theme-gradient-colors-warm, 1), nth($theme-gradient-colors-warm, 2));
$theme-gradient-cold      : linear-gradient(135deg, nth($theme-gradient-colors-cold, 1), nth($theme-gradient-colors-cold, 2));
$theme-gradient-grey      : linear-gradient(100deg, nth($theme-gradient-colors-grey, 1), nth($theme-gradient-colors-grey, 2));
$theme-gradient-pink      : linear-gradient(to top, nth($theme-gradient-colors-pink, 1), nth($theme-gradient-colors-pink, 2));
$theme-gradient-white     : linear-gradient(84.94deg, nth($theme-gradient-colors-white, 1) 4.89%, nth($theme-gradient-colors-white, 2) 96.93%);
$theme-gradient-brown     : linear-gradient(180deg, nth($theme-gradient-colors-brown, 1) 0%, nth($theme-gradient-colors-brown, 2) 100%);

$theme-gradient-gold      : linear-gradient(270deg, $clr-gold-1 -0.46%, $clr-gold-2 99.54%);

$theme-gradient-ice       : linear-gradient(to top, nth($theme-gradient-colors-ice, 1), nth($theme-gradient-colors-ice, 2));
$theme-gradient-ice-blue  : linear-gradient(to top, nth($theme-gradient-colors-ice-blue, 1), nth($theme-gradient-colors-ice-blue, 2));

$theme-gradient-sage      : linear-gradient(to right, nth($theme-gradient-colors-sage, 1), nth($theme-gradient-colors-sage, 2));

$theme-gradient-sg-1      : linear-gradient(210.45deg, nth($theme-gradient-colors-sg-1, 1) 0%, nth($theme-gradient-colors-sg-1, 2) 100%);
$theme-gradient-sg-2      : linear-gradient(210.45deg, nth($theme-gradient-colors-sg-2, 1) 0%, nth($theme-gradient-colors-sg-2, 2) 100%);
$theme-gradient-sg-3      : linear-gradient(210.45deg, nth($theme-gradient-colors-sg-3, 1) 0%, nth($theme-gradient-colors-sg-3, 2) 100%);
$theme-gradient-sg-4      : linear-gradient(210.45deg, nth($theme-gradient-colors-sg-4, 1) 0%, nth($theme-gradient-colors-sg-4, 2) 100%);
$theme-gradient-sg-5      : linear-gradient(210.45deg, nth($theme-gradient-colors-sg-5, 1) 0%, nth($theme-gradient-colors-sg-5, 2) 100%);
$theme-gradient-sg-6      : linear-gradient(210.45deg, nth($theme-gradient-colors-sg-6, 1) 0%, nth($theme-gradient-colors-sg-6, 2) 100%);

$theme-gradient-gold      : linear-gradient(309.57deg, $clr-gold-1 0%, $clr-gold-2 100%) !default;

$theme-gradient-text-cold : linear-gradient(90deg, #5E5AD6 0.16%, #E391C5 100.16%) !default;
$theme-gradient-text-warm : linear-gradient(90deg, #E69E34 0.08%, #F47248 52.16%, #F8ACDE 100.08%) !default;

$theme-gradients: () !default;
$theme-gradients: map-merge(
  (
    'primary'        : $theme-gradient-primary,
    'warm'           : $theme-gradient-warm,
    'cold'           : $theme-gradient-cold,
    'grey'           : $theme-gradient-grey,
  ),
  $theme-gradients
);

$socialButtonColors: (
  wechat: #7bb32e,
  facebook: #3b5998,
  twitter: #26a6d1,
  embed: $theme-dark-ltr,
  mail: $theme-dark-ltr,
);
