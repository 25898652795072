
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  text-align: left;

  & + & {
    margin-top: $gl-spacer-s;
  }
}

