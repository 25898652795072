
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.hint {
  @include button-reset();

  font-size: $gl-font-size-xxs;
  color: $theme-dark;

  &:hover {
    text-decoration: underline;
  }
}
