///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
// Use 'gl-' prefix for common global variables

// Standardise some UI treatments.

// ===== GlOBAL =====
$important: config(important);

// Animation
$gl-duration-base    : 300ms !default;
$gl-timing-func : ease-in-out !default;
$gl-transition-base  : all $gl-duration-base $gl-timing-func !default;
$gl-link-transition  : math.div($gl-duration-base, 2) color $gl-timing-func;

// Font families
$gl-font-family-base : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !default;
$gl-font-family-alt: 'Lora', Georgia, serif !default;

// Borders
$gl-border-width-base : 1px !default;
$gl-border-width-md   : 2 * $gl-border-width-base !default;
$gl-radius-base       : 4px !default;
$gl-radius-md         : 12px !default;
$gl-radius-lg         : 24px !default;

// Disabled elements
$gl-disabled-opacity: 0.4 !default;

//Overlays
$gl-overlay-bg: rgba($theme-dark-dkr, 0.75);

//Box Shadows
$gl-box-shadow-base : 0 2px 4px 0 rgba(0, 0, 0, 0.05) !default;
$gl-box-shadow-sm   : 0 4px 20px 0 rgba(0, 0, 0, 0.05) !default;
$gl-box-shadow-m    : 0 4px 20px 0 rgba(0, 0, 0, 0.08) !default;
$gl-box-shadow-m-x  : 4px 0 20px 0 rgba(0, 0, 0, 0.08) !default; // x-axis medium shadow
$gl-box-shadow-m-dk  : 0 4px 20px 0 rgba(0, 0, 0, 0.15) !default; // dropdowns
$gl-box-shadow-l    : 0 14px 34px 0 rgba(0, 0, 0, 0.08) !default;
$gl-box-shadow-xlg  : 0 10px 40px 0 rgba(0, 0, 0, 0.1) !default;

//Asset sizes (e.g. icons)
$gl-asset-size-4xs  : 11px !default;
$gl-asset-size-3xs  : 14px !default;
$gl-asset-size-xxs  : 20px !default;
$gl-asset-size-xs   : 24px !default;
$gl-asset-size-sm   : 28px !default;
$gl-asset-size-md   : 32px !default;
$gl-asset-size-lg   : 48px !default;
$gl-asset-size-xl   : 64px !default;
$gl-asset-size-xxl  : 128px !default;

$asset-size: () !default;
$asset-size: map-merge(
  (
    '4xs' : $gl-asset-size-4xs,
    '3xs' : $gl-asset-size-3xs,
    'xxs' : $gl-asset-size-xxs,
    'xs'  : $gl-asset-size-xs,
    'sm'   : $gl-asset-size-sm,
    'md'   : $gl-asset-size-md,
    'lg'   : $gl-asset-size-lg,
    'xl'  : $gl-asset-size-xl,
    'xxl' : $gl-asset-size-xxl
  ),
  $asset-size
);

// z-index
$gl-z-index-0: 0 !default;
$gl-z-index-1: 1 !default;
$gl-z-index-2: 2 !default;
$gl-z-index-3: 3 !default;
$gl-z-index-4: 4 !default;
$gl-z-index-5: 5 !default;
$gl-z-index-pre-max: 999 !default;
$gl-z-index-max: 1000 !default;
$gl-z-index-modal: 9998 !default;

// Headings
$gl-font-size-h1:  48px !default;
$gl-font-size-h2:  43px !default;
$gl-font-size-h3:  32px !default;
$gl-font-size-h4:  28px !default;
//$gl-font-size-h5:  24px !default;
$gl-font-size-h5:  22px !default;
$gl-font-size-h6:  20px !default;
$gl-font-size-sub-header: 18px;

$gl-header-font-sizes: () !default;
$gl-header-font-sizes: map-merge(
  (
    'h1'  : $gl-font-size-h1,
    'h2'  : $gl-font-size-h2,
    'h3'  : $gl-font-size-h3,
    'h4'  : $gl-font-size-h4,
    'h5'  : $gl-font-size-h5,
    'h6'  : $gl-font-size-h6,
    'sub' : $gl-font-size-sub-header,
  ),
  $gl-header-font-sizes
);

// Validation
$gl-validation-success-color : $theme-success !default;
$gl-validation-error-color  : $theme-alert !default;

//Links

$gl-link-color: $theme-primary;
$gl-link-hover-color: $theme-primary;

// ===== [C] FORMS =====

// Animation
$gl-input-transition         : all 0.1s !default;
// Font
$gl-input-font-family        : $gl-font-family-base !default;
$gl-input-line-height        : $gl-line-height-sm !default;
// Input

// Border
$gl-input-border-radius      : 24px !default;
$gl-input-border-width       : $gl-border-width-base !default;
//States
$gl-input-disabled-opacity   : $gl-disabled-opacity !default;

$gl-form-group-btn-padding-x: 10px !default;
// Variants
$gl-input-primary-background: $theme-light-lt !default;
$gl-input-primary-color: $theme-dark-dk !default;
$gl-input-primary-border-color: rgba($theme-dark-dkr, 0.12) !default;
$gl-input-primary-placeholder-color: $theme-dark !default;
$gl-input-primary-focus-border-color: $theme-primary !default;

$gl-input-dark-background: $theme-light-dkr !default;
$gl-input-dark-color: $theme-dark-dk !default;
$gl-input-dark-border-color: transparent !default;
$gl-input-dark-placeholder-color: rgba(0,0,0,0.69) !default;
$gl-input-dark-placeholder-color: rgba(0,0,0,0.69) !default;

$gl-input-variants: () !default;
$gl-input-variants: map-merge(
  (
    primary: (
      background         : $gl-input-primary-background,
      color              : $gl-input-primary-color,
      border-color       : $gl-input-primary-border-color,
      placeholder-color  : $gl-input-primary-placeholder-color,
      focus-border-color : $gl-input-primary-focus-border-color
    ),
    primary-dark: (
      background         : $gl-input-primary-background,
      color              : $gl-input-primary-color,
      border-color       : $gl-input-primary-border-color,
      placeholder-color  : $gl-input-primary-color,
      focus-border-color : $gl-input-primary-focus-border-color
    ),
    dark: (
      background         : $gl-input-dark-background,
      color              : $gl-input-dark-color,
      border-color       : $gl-input-dark-border-color,
      placeholder-color  : $gl-input-dark-placeholder-color,
      focus-border-color : transparent$important
    ),
    primary-ltst: (
      background         : $theme-primary-ltst,
      color              : $theme-dark-lt,
      border-color       : transparent,
      placeholder-color  : $theme-dark-lt,
      focus-border-color : transparent$important
    )
  ),
  $gl-input-variants
);

// sizes

$gl-input-xs-padding-x   : 12px !default;
$gl-input-xs-padding-y   : 11px !default;
$gl-input-xs-height      : 32px !default;
$gl-input-xs-font-size   : $gl-font-size-xs !default;

$gl-input-sm-padding-x   : 12px !default;
$gl-input-sm-padding-y   : 11px !default;
$gl-input-sm-height      : 38px !default;
$gl-input-sm-font-size   : $gl-font-size-s !default;

$gl-input-md-padding-x   : 16px !default;
$gl-input-md-padding-y   : 14px !default;
$gl-input-md-height      : 40px !default;
$gl-input-md-font-size   : $gl-font-size-base !default;

$gl-input-lg-padding-x   : 16px !default;
$gl-input-lg-padding-y   : 14px !default;
$gl-input-lg-height      : 46px !default;
$gl-input-lg-font-size   : $gl-font-size-base !default;

$gl-input-sizes: () !default;
$gl-input-sizes: map-merge(
  (
    'xs': (
      padding-x: $gl-input-xs-padding-x,
      padding-y: $gl-input-xs-padding-y,
      height: $gl-input-xs-height,
      font-size: $gl-input-xs-font-size,
    ),
    'sm': (
      padding-x: $gl-input-sm-padding-x,
      padding-y: $gl-input-sm-padding-y,
      height: $gl-input-sm-height,
      font-size: $gl-input-sm-font-size,
    ),
    'md': (
      padding-x: $gl-input-md-padding-x,
      padding-y: $gl-input-md-padding-y,
      height: $gl-input-md-height,
      font-size: $gl-input-md-font-size,
    ),
    'lg': (
      padding-x: $gl-input-lg-padding-x,
      padding-y: $gl-input-lg-padding-y,
      height: $gl-input-lg-height,
      font-size: $gl-input-lg-font-size,
    )
  ),
  $gl-input-sizes
);

$gl-label-xs-font-size   : $gl-font-size-xxs !default;
$gl-label-sm-font-size   : $gl-font-size-xs !default;
$gl-label-lg-font-size   : $gl-font-size-base !default;

$gl-label-sizes: () !default;
$gl-label-sizes: map-merge(
  (
    'xs': $gl-label-xs-font-size,
    'sm': $gl-label-sm-font-size,
    'lg': $gl-label-lg-font-size,
  ),
  $gl-label-sizes
);

// Validation
$gl-input-icon-size       : $gl-asset-size-sm !default;
$gl-input-icon-position-y : 8px !default; // Hack: icon vertical centering
$gl-input-icon-position-x : 12px !default;

// Navigation Bar
$gl-navbar-height-desktop: 64px;
$gl-navbar-height-mobile: 56px;

// FOOTER
$gl-footer-margin-top: 60px;
