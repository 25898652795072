
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #FORM FIELD
   ========================================================================== */
// Textual form fields (e.g. input[type='text'], textarea, etc)
$border-radius: $gl-input-border-radius;
$error-color:  $gl-validation-error-color;
$success-color:  $gl-validation-success-color;
$icon-x-margin: $gl-spacer-xs;
$textarea-height: 150px;
// Default webkit background color for autofilled fields
// $autofill-background: rgb(250, 255, 189);
$autofill-background: rgb(232, 240, 254);
$important: config(important);


.field {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background-color: $theme-light-lt;
  color: $theme-dark-dk;
  background-clip: padding-box;
  border: $gl-input-border-width solid transparent;
  border-radius: $border-radius;
  transition: $gl-input-transition;
  position: relative;

  &.is-disabled,
  &.is-readonly {
    opacity: $gl-disabled-opacity;
    cursor: not-allowed;
  }
}

.round {
  border-radius: 24px;
  overflow: hidden;
}

.input {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  display: block;
  padding: $gl-input-sm-padding-y $gl-input-sm-padding-x;
  font-size: $gl-input-sm-font-size;
  font-family: $gl-input-font-family;
  line-height: $gl-input-line-height;
  border-radius: $border-radius;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background: none;
  background-image: none;
  color: inherit;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
  z-index: $gl-z-index-1;


  &.with-icon {
    padding-right: math.div($gl-input-sm-padding-x, 2);
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Placeholder
  &::placeholder {
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    font-weight: normal;
    opacity: 1;
    text-transform: none;
  }

  // Read-only input
  &[readonly],
  &[disabled] {
    cursor: not-allowed;
  }

  &:-webkit-autofill + .autofill-dummy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $autofill-background;
    border-radius: $border-radius;
  }


}



.input-container {
  position: static;
  display: flex;
  align-items: center;
}

.success {
  border-color: $success-color $important;

  .icon {
    color: $success-color;
  }
}
//Colors
.error {
  border-color: $error-color $important;


  .icon {
    color: $error-color;
  }
}

// Variants
@each $variant, $opts in $gl-input-variants {
  .variant--#{'' + $variant} {
    color: map-get($opts, color);
    background-color: map-get($opts, background);
    border-color: map-get($opts, border-color);

    .input {
      font-weight: map-get($opts, font-weight);
      &::placeholder {
        color: map-get($opts, placeholder-color);
      }

      // HACK: remove webkit's yellow autofill background
      &:-webkit-autofill  {
        // box-shadow: inset 0 0 0px 9999px map-get($opts, background);
      }
    }

    @if map-get($opts, focus-border-color) != null {
      &.is-focused {
        border-color: map-get($opts, focus-border-color);
        outline: 0;
        box-shadow: none;
      }
    }
  }
}


// sizes
@each $size, $opts in $gl-input-sizes {
  .size--#{$size} {
    $fs:  map-get($opts, font-size);
    padding: 0 map-get($opts, padding-x);
    font-size: $fs;
    height: map-get($opts, height);

    @if ($fs < 16px) {
      @supports (-webkit-touch-callout: none) {
        font-size: 16px;
      }
    }



    &.with-icon {
      padding-right: math.div(map-get($opts, padding-x), 2);
    }

    &.textarea {
      padding-top: map-get($opts, padding-y);
      padding-bottom: map-get($opts, padding-y);
    }
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;

  &--first {
    order: -1;

    .icon {
      margin-left: $icon-x-margin;
    }
  }

  &--last {
    order: 0;

    .icon {
      margin-right: $icon-x-margin;
    }
  }
}

.textarea {
  resize: none;
  height: auto;
  min-height: 100%;

  &__icon {
    align-items: flex-start;
    padding-top: $icon-x-margin;
  }
}

.side-link {
  display: flex;
  align-items: center;

  &__button {
    @include button-reset();
    margin-right: $gl-spacer-xs;
    font-size: $gl-font-size-xs;
    color: $theme-dark;
  }
}
