///* ========================================================================
//   #CORE
//   ======================================================================== */

// Variables should follow the flollowing formula for consistent naming.
//
// ${component/prefix}-{state}-{property}-{alternative}-{size}
//
// Examples:
//
// component    : nav-, modal-
// prefix       : global-, clr-
// state        : active, disabled
// property     : font-family, font-size, radius
// alternative  : base, alt, primary, secondary
// size         : lg, m, sm, xs
//
// Name examples:
// $nav-link-disabled-color, $modal-content-box-shadow-xs, $gl-font-family-alt.

// This core file sets up oneclass’ most important setup variables. They
// underpin a lot of how the framework functions and should be modified and
// preconfigured with caution.

// Base typographical styles and baseline grid.

// Font Size
$gl-font-size-4xs  : 9px !default;
$gl-font-size-3xs  : 11px !default;
$gl-font-size-xxs  : 12px !default;
$gl-font-size-xs   : 13px !default;
$gl-font-size-s    : 14px !default;
$gl-font-size-s2   : 15px !default;
$gl-font-size-base : 16px !default;
$gl-font-size-lg   : 17px !default;
//$gl-font-size-lg   : 18px !default;
//$gl-font-size-xlg  : 24px !default;
$gl-font-size-xlg  : 22px !default;
$gl-font-size-2xlg : 36px !default;

$font-sizes: () !default;
$font-sizes: map-merge(
  (
    '4xs'  : $gl-font-size-4xs,
    '3xs'  : $gl-font-size-3xs,
    'xxs'  : $gl-font-size-xxs,
    'xs'   : $gl-font-size-xs,
    's'    : $gl-font-size-s,
    's2'    : $gl-font-size-s2,
    'base' : $gl-font-size-base,
    'lg'   : $gl-font-size-lg,
    'xlg'  : $gl-font-size-xlg,
    '2xlg'  : $gl-font-size-2xlg
  ),
  $font-sizes
);

// Line Height
$gl-line-height-base:    1.5 !default;
$gl-line-height-md:      1.29 !default;
$gl-line-height-sm:      1.25 !default;
$gl-line-height-xs:      1 !default;

// Base grids settings
$gl-grid-gap-base: 24px !default;
$gl-container-mobile-padding: 24px !default;

$gl-grid-columns: 12 !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$breakpoints: (
  'xs' : 0,
  'sm' : 576px,
  'md' : 768px,
  'lg' : 992px,
  'xl' : 1140px,
  'xxl': 1200px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 920px,
  xl: 1080px + $gl-grid-gap-base, // 1080px + 24px
  xxl: 1128px + $gl-grid-gap-base
) !default;

$gl-mobile-breakpoint: 'lg';
$gl-tablet-breakpoint: 'md';
$gl-phone-breakpoint: 'sm';

// Spacing values are determined based on project’s global grid gap.

$gl-spacer: $gl-grid-gap-base !default;

$gl-spacer-4xs: 2px !default; // 2px  0.125rem
$gl-spacer-3xs: 4px !default; // 4px  0.25rem
$gl-spacer-xxs: 8px !default; // 8px  0.5rem
$gl-spacer-xs:  12px !default;  // 12px 0.75rem
$gl-spacer-s:   16px !default; // 16px 1rem
$gl-spacer-m:   24px !default;    // 24px 1.5rem
$gl-spacer-l:   32px !default; // 32px 2rem
$gl-spacer-l2:  40px !default; // 32px 2rem
$gl-spacer-xl:  48px !default;    // 48px 3rem
$gl-spacer-2xl: 72px !default;    // 72px 4.5rem
$gl-spacer-3xl: 96px !default;    // 96px 6rem
$gl-spacer-4xl: 128px !default; // 128px 8rem

$gl-grid-gaps: (
  '3xs' : $gl-spacer-3xs,
  'xxs' : $gl-spacer-xxs,
  'xs'  : $gl-spacer-xs,
  's'   : $gl-spacer-s,
  'm'   : $gl-spacer-m,
  'l'   : $gl-spacer-l,
  'xl'  : $gl-spacer-xl,
  '2xl' : $gl-spacer-2xl,
  '3xl' : $gl-spacer-3xl,
  '4xl' : $gl-spacer-4xl,
) !default;

// // [TODO] review and remove unused spacing sizes after refactoring
// $gl-spacing-factor-3xs: 0.16 !default; // 4px  0.25rem
// $gl-spacing-factor-xxs: 0.33 !default; // 8px  0.5rem
// $gl-spacing-factor-xs:  0.5 !default;  // 12px 0.75rem
// $gl-spacing-factor-s:   0.66 !default; // 16px 1rem
// $gl-spacing-factor-m:   1 !default;    // 24px 1.5rem
// $gl-spacing-factor-l:   1.33 !default; // 32px 2rem
// $gl-spacing-factor-xl:  2 !default;    // 48px 3rem
// $gl-spacing-factor-2xl: 3 !default;    // 72px 4.5rem
// $gl-spacing-factor-3xl: 4 !default;    // 96px 6rem
// $gl-spacing-factor-4xl: 5.33 !default; // 128px 8rem

// // Private/framework-only reassignment. Do not alter anything below.
// $gl-spacer-3xs: round($gl-spacer * $gl-spacing-factor-3xs) !default;
// $gl-spacer-xxs: round($gl-spacer * $gl-spacing-factor-xxs) !default;
// $gl-spacer-xs:  round($gl-spacer * $gl-spacing-factor-xs) !default;
// $gl-spacer-s:   round($gl-spacer * $gl-spacing-factor-s) !default;
// $gl-spacer-m:   round($gl-spacer * $gl-spacing-factor-m) !default;
// $gl-spacer-l:   round($gl-spacer * $gl-spacing-factor-l) !default;
// $gl-spacer-xl:  round($gl-spacer * $gl-spacing-factor-xl) !default;
// $gl-spacer-2xl: round($gl-spacer * $gl-spacing-factor-2xl) !default;
// $gl-spacer-3xl: round($gl-spacer * $gl-spacing-factor-3xl) !default;
// $gl-spacer-4xl: round($gl-spacer * $gl-spacing-factor-4xl) !default;

$spacers: () !default;
$spacers: map-merge(
  (
    '4xs': $gl-spacer-4xs,
    '3xs': $gl-spacer-3xs,
    'xxs': $gl-spacer-xxs,
    'xs' : $gl-spacer-xs,
    's'  : $gl-spacer-s,
    'm'  : $gl-spacer-m,
    'l'  : $gl-spacer-l,
    'l2' : $gl-spacer-l2,
    'xl' : $gl-spacer-xl,
    '2xl': $gl-spacer-2xl,
    '3xl': $gl-spacer-3xl,
    '4xl': $gl-spacer-4xl
  ),
  $spacers
);
