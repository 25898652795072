
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
// Star Rating

.container {
  display: inline-flex;
  align-items: center;
}

.item {
  @include button-reset;

  user-select: none;
  outline: none;
  cursor: default;
  padding: 0 2px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.is-disabled * {
  fill: $theme-dark-ltr;
}

.hover {
  cursor: pointer;
}
