
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.ambassador_badge_container {
  @include size(24px);
  min-width: 24px;
  @include desktop {
    @include size(30px);
    min-width: 30px;
  }
  background: #FFFFFF;
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  right: 4px;
  bottom: 4px;

  @include desktop {
    right: 12px;
    bottom: 12px;
  }

  img {
    @include size(20px);
    min-width: 20px;

    @include desktop {
      @include size(24px);
      min-width: 24px;
    }
  }
}

.img {
  max-width: none;
}
