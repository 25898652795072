
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #AVATAR
   ========================================================================== */

// A profile image thumbnail
$avatar-size: 72px;
$avatar-size-mobile: $gl-asset-size-md;
$avatar-bg-color: $theme-light-lt;
$avatar-color: $theme-primary;
$icon-size-mobile: 12px;
// $avatar-letter-spacing: 3.6px;
// $avatar-font-size: $gl-font-size-xlg;
// $avatar-font-weight: 500;

.container {
  @include size($avatar-size);
  display: inline-block;
  position: relative;


  @include mobile {
    @include size($avatar-size-mobile);
  }


  // &::before {
  //   content: attr(data-initials);
  //   letter-spacing: rem($avatar-letter-spacing);
  //   text-transform: uppercase;
  //   font-size: $avatar-font-size;
  //   font-weight: $avatar-font-weight;
  //   color: $avatar-color;
  //   z-index: 1;

  //   @include center-absolute();
  // }

  .avatar__icon {
    // Calculate an icon position, which is equal to x/y coordinates
    // of the intersection point of the circle inscribed in a
    // bounding rectangle and its diagonal
    // pos = radius + sin(45 deg) * radius

    $pos: 50% + math.div(1.41421356237, 2) * 50%;
    position: absolute;
    top: $pos;
    left: $pos;
    transform: translate(-50%, -50%);

    @include mobile {
      .svg {
        @include size($icon-size-mobile);
      }
    }
  }
}

.img-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background-color: $avatar-bg-color;

  &.default {
    border: 1px solid $theme-light-dk;
  }

  .default-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 56%;

    &__icon {
      width: 100%;
      height: auto;
    }
  }
}


.img {
  width: 100%;
  // border-radius: 50%;
  display: block;
  margin: 0;
}


