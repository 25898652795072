
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$colors: (
  grey: (
    background: #F0F0F0,
    color: #686868,
  ),
  blue: (
    background:#EDF1FF,
    color: #3C61DF,
  ),
  purple: (
    background: #ECE5FF,
    color: #7827FF,
  ),
  orange: (
    background: #FBEFDF,
    color: #A15C00,
  )
);

$sizes: (
  xs: (
    padding: 1px 5px,
    margin: 0 $gl-spacer-3xs,
    font-size: 10px,
    border-radius: 3px,
  ),
);

.container {
  font-weight: 600;
  letter-spacing: -0.154px;
}

@each $color, $opts in $colors {
  .container--#{'' + $color} {
    background: map-get($opts, background);
    color: map-get($opts, color);
  }
}

@each $size, $opts in $sizes {
  .container--#{'' + $size} {
    padding: map-get($opts, padding);
    margin: map-get($opts, margin);
    font-size: map-get($opts, font-size);
    border-radius: map-get($opts, border-radius);
  }
}

.badge_text {
  display: flex;
  align-items: center;
  margin-left: 2px;
}
