
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.label {
  display: inline-block;
  vertical-align: middle;
  margin-left: $gl-spacer-xxs;
}
