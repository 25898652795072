
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.container {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.placeholder {
  max-width: 100%;
}

.img {
  transition: opacity 0.5s;
  display: block;
  margin: 0;
  padding: 0;
}

.responsive {
  width: 100%;
}

.loading {
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
}
