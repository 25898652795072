
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$input-size: 16px;

.control {
  @include size($input-size);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba($theme-dark-dkr, 0.12);
  cursor: pointer;
  color: $theme-light-lt;

  .icon {
    opacity: 0;
  }
}



.input {
  display: grid;
  grid-template-areas: "checkbox";

  > * {
    grid-area: checkbox;
  }

  input {
    @include size($input-size);
    opacity: 0;

    position: relative;
    z-index: 1;
    // &:focus + .control {
    //   box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    // }

    &:checked + .control {
      background: $theme-primary;
      border-color: transparent;

      .icon {
        opacity: 1;
      }
    }

    &:disabled + .control {
      color: var(--disabled);
    }
  }
}
