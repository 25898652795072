
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.message {
  $self: &;

  font-size: $gl-font-size-xxs;
  color: $theme-dark;
  text-align: left;
}

.button {
  @include button-reset;

  transition: $gl-link-transition;

  &:hover {
    color: $theme-primary;
  }
}

.error {
  color: $gl-validation-error-color;
}


