
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.label {
  color: $theme-dark-dk;
  font-size: $gl-font-size-s;
  font-weight: 500;
  display: block;
  margin-bottom: $gl-spacer-3xs;

  @each $size, $val in $gl-label-sizes {
    &.#{$size} {
      font-size: $val;
    }
  }
}
