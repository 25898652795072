
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$vertical-space-between-multiselect-badges: 8px;

.container {
  position: relative;
}

.list {
  position: absolute;
  top: 100%;
  z-index: $gl-z-index-pre-max;
  left: 0;
  margin-top: 3px;

  overflow: hidden;
}

.above {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 3px;
}

.select__label[readonly]:not([disabled]) {
  cursor: pointer;

  &::placeholder {
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    // font-weight: 500;
    color: $theme-dark-dk;
    opacity: 1;
    text-transform: none;
  }
}

.select__icon {
  color: $theme-dark;
  cursor: pointer;
}

.search {
  padding: 12px 4px 4px;
}

.search__field {
  border-radius: 0;
}

.options {
  overflow-y: auto;
  max-height: 220px;
  text-align: left;
}


.cancel {
  color: $theme-dark;
  padding: 0;
}

.clear {
  @include button-reset();
  cursor: pointer;
  margin-right: $gl-spacer-s;
  color: rgba($theme-dark-dkr, 0.25);
}

.multiselect_pseudo_input {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 24px;

  // TODO: make this height dynamic using JS
  min-height: 48px;

  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.multiselect_input_row {
  height: 100%;
  margin-bottom: $vertical-space-between-multiselect-badges;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.multiselect_badge {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: $theme-light;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  margin-top: $vertical-space-between-multiselect-badges;

  &__clear {
    margin: 0;
    margin-left: 4px;
    display: flex;
    align-items: center;
  }

  & + & {
    margin-left: $gl-spacer-3xs;
  }
}
