
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    


.container {
  padding: $gl-spacer-xs $gl-spacer-l;
  color: $theme-dark-dkr;
  display: block;
  // min-height: 56px;
}

.thumb {
  width: 32px;
  border-radius: 4px;
  overflow: hidden;
}

.label {
  font-weight: 600;
  font-size: $gl-font-size-s2;

  color: $theme-dark-dk;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.info {
  color: $theme-dark-lt;
  font-size: $gl-font-size-xxs;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 4px;
}


.container:hover,
.container.highlighted {
  background: $theme-primary;

  .label {
    color: $theme-light-lt;
  }


  .info {
    color: rgba(255, 255, 255, 0.93)
  }
}
