
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
$tooltip-width: 250px;
$tooltip-arrow-size: 8px;

$tooltip-color: $theme-dark;
$tooltip-bg-color: $theme-light-lt;

$tooltip-dark-color: $theme-light-lt;
$tooltip-dark-bg-color: $theme-dark-dkr;

.target {
  position: relative;
  display: inline-flex;
  align-content: center;
}

.popper {
  $self: &;

  position: absolute;
  border-radius: 12px;
  box-shadow: $gl-box-shadow-sm;
  padding: $gl-spacer-s;
  z-index: $gl-z-index-max;
  font-size: $gl-font-size-s;
  background-color: $tooltip-bg-color;
  color: $tooltip-color;
  width: $tooltip-width;
  text-align: left;
  opacity: 1;
  line-height: $gl-line-height-base;

  &.small {
    font-size: $gl-font-size-xxs;
    padding: $gl-spacer-s;
  }

  &.padding-small {
    padding: $gl-spacer-3xs $gl-spacer-xs;
  }

  &.dark {
    background-color: $tooltip-dark-bg-color;
    color: $tooltip-dark-color;

    .arrow {
      border-color: $tooltip-dark-bg-color;
    }
  }

  &.auto {
    width: auto;
    white-space: nowrap;
  }

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: $tooltip-arrow-size;
    border-width: $tooltip-arrow-size;
    border-color: $tooltip-bg-color;
  }



  @at-root {
    #{$self}[x-placement^="top"] {
      margin-bottom: $tooltip-arrow-size;

      .arrow {
        border-bottom-width: 0;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        bottom: - $tooltip-arrow-size;
        left: calc(50% - #{$tooltip-arrow-size});
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    #{$self}[x-placement^="bottom"] {
      margin-top: $tooltip-arrow-size;

      .arrow {
        border-top-width: 0;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        top: - $tooltip-arrow-size;
        left: calc(50% - #{$tooltip-arrow-size});
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    #{$self}[x-placement^="right"] {
      margin-left: $tooltip-arrow-size;

      .arrow {
        border-left-width: 0;
        border-left-color: transparent;
        border-top-color: transparent;
        border-bottom-color: transparent;
        left: - $tooltip-arrow-size;
        top: calc(50% - #{$tooltip-arrow-size});
        margin-left: 0;
        margin-right: 0;
      }
    }

    #{$self}[x-placement^="left"] {
      margin-right: $tooltip-arrow-size;

      .arrow {
        border-right-width: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        right: - $tooltip-arrow-size;
        top: calc(50% - #{$tooltip-arrow-size});
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.tooltip_overlay {
  content: '';

  background: rgba($theme-dark-dkr, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $gl-z-index-pre-max;
}
