
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
.placeholder {
  background: $theme-light ;

  &.animated {
    animation: shimmer 1.2s ease-in-out infinite;
  }
}

@keyframes shimmer{
  0% {
    opacity:.8
  }
  50% {
    opacity:.5
  }
  100% {
    opacity:.8
  }
}
