/* ==========================================================================
   #BUTTON MIXINS
   ========================================================================== */

// Reset default button styles

@mixin button-reset {
  background: none; // reset button default background
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  outline: none;
  text-align: inherit;
  line-height: inherit;
}

// Button sizes
@mixin button-size($pv, $ph, $font-size, $line-height) {
  padding: $pv $ph;
  font-size: $font-size;
  line-height: $line-height;
}

// Button variants
@mixin button-variant(
  $background,
  $color,
  $hover-background: tint($background, 14%),
  $important: null
) {
  @include hover-focus {
    background-color: $hover-background $important;
    border-color: $hover-background $important;
    color: $color $important;
    outline: none;
  }

  &,
  &.is-disabled,
  &.is-inactive,
  &:disabled {
    background-color: $background $important;
    border-color: $background $important;
    color: $color $important;
  }
}

// Outline button variants
@mixin button-outline-variant(
  $color,
  $hover-background,
  $border-color,
  $background,
  $important: null
) {
  @include hover-focus {
    background-color: $hover-background $important;
    border-color: $border-color $important;
    color: $color $important;
    outline: none;
  }

  &,
  &.is-disabled,
  &.is-inactive,
  &:disabled {
    background-color: $background;
    border-color: $border-color;
    color: $color;
  }
}

@mixin socialButtonColor($type) {
  $c: map-get($socialButtonColors, $type);

  background-color: $c;
  @include hover-focus-active {
    background-color: $c;
  }
};
