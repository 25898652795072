
      @use "sass:math";
      @import "/var/www/next/packages/shared/styles/_main.settings.scss";
      @import "/var/www/next/packages/shared/styles/_main.tools.scss";
    
/* ==========================================================================
   #FORM GROUPS
   ========================================================================== */

// Default Vars
$form-group-spacing: $gl-spacer-s;
$form-text-margin-top: $gl-spacer-xxs;

.container {
  margin-bottom: $form-group-spacing;
  position: relative;
  text-align: left;

  &:last-child { margin-bottom: 0; }
}
